.Terms {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: #fff;

  .TermsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
    margin: auto;

    p {
      text-align: left;
    }

    .p0 {
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Terms {
    padding: 20px 15px;
  }
}
