@import '../../variables';

.mobileMenu {
  width: 90vw;
  height: 100vh;
  z-index: 990;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 5px;
  transition: 0.5s;
  box-shadow: $shadow;

  .mobileMenuTop {
    display: inline-flex;
    width: 100%;
    padding: 0 25px 0 10px;
    align-items: center;

    h1 {
      font-size: 24px;
      margin: auto;
      font-family: Arial, Helvetica, sans-serif;
    }

    .btn {
      padding: 6px 5px;
    }
  }

  .mobileMenuContent {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 15px;

    div.LanguageToggle > button {
      margin-right: 0 !important;
    }

    .toolbarIcon {
      height: 18px;
      width: auto;
      margin-bottom: 2px;
      margin-right: 5px;
    }

    a .btn,
    .btn {
      color: #000;
      text-align: left;
      padding: 6px 10px;
    }

    hr {
      width: 100%;
      border-top: 1px solid $gray;
    }

    a p {
      color: #000;
    }

    .mNavRow {
      display: inline-flex;
      width: 100%;
      height: 35px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      justify-content: space-between;

      a {
        margin: auto 0;
        height: 35px;

        p {
          margin: 6px 0;
        }
      }

      .btn {
        height: 32px;
        padding: 0 10px 3px 10px;
        border: none !important;
        border-radius: 5px;
        background-color: #fff;

        p {
          margin: 2px auto 0px auto;
          color: #000;
          width: 8px;
          text-align: center;
        }
      }

      .btn:active {
        background-color: #fff;
      }

      .btn:focus {
        box-shadow: none;
      }
    }

    .mLevel1 {
      padding-left: 10px;

      a {
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    .mLevel2 {
      padding-left: 40px;

      a {
        text-transform: uppercase;
        font-weight: 450;
      }
    }

    .mLevel3 {
      padding-left: 70px;

      a {
        font-weight: 450;

        p {
          font-size: 13px;
          margin-top: 8px;
        }
      }
    }
  }
}
