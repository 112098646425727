.MultiCarouselBrands {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .logoDiv {
    display: flex;
    justify-content: center;
    width: 100px;

    img {
      max-height: 45px;
      max-width: 135px;
    }
  }
}

.Footer {
  .react-multiple-carousel__arrow--right {
    right: 2px;
  }

  .react-multiple-carousel__arrow--left {
    left: 2px;
  }

  .react-multiple-carousel__arrow::before {
    color: #000;
  }

  .react-multiple-carousel__arrow {
    background: none;
    border: 1px solid #fff;
    opacity: 1;
  }

  .react-multiple-carousel__arrow:hover {
    background-color: #fff;
    border: 1px solid #000;
  }

  .react-multiple-carousel__arrow:focus {
    outline: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .MultiCarouselBrands .logoDiv img {
    max-height: 30px;
    max-width: 90px;
  }
}
