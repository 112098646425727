@import '../../variables';

.Favorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px;
  background-color: #fff;

  h1 {
    font-size: 24px;
    margin-bottom: 2rem;
  }

  h4 {
    margin-top: 1rem;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    margin: 5px;
  }

  .favoritesContainer {
    margin-bottom: 2rem;
    .row .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #cccfd2;
      margin: 0 20px auto 20px;
      min-height: 276px;
      padding: 5px;

      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        padding: 2px;

        #favoritesCog {
          width: 18px;
          height: 18px;
          margin: auto;
        }
      }

      form {
        font-size: 14px;
        max-width: 60%;
        text-align: left;
        margin: 15px auto;

        .btn {
          padding: 8px;
        }
      }
    }
  }

  .table {
    margin: 5px auto;
    max-width: 80%;
    font-size: 14px;
    margin-bottom: 2rem;

    tbody tr:hover {
      cursor: pointer;
    }
  }

  .favorites {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: auto;
    width: 100%;
    max-width: calc(5 * 240px);
    flex-wrap: wrap;

    p {
      margin: 0;
    }
  }
}
.Favorites {
  .emptyFavorites {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;

    img {
      margin: 4rem 0 24px 0;
    }

    p {
      font-size: 16px;
      margin: 0 0 7rem 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Favorites {
    padding: 15px;

    h1 {
      margin: auto;
      padding: 15px;
    }

    .favoritesContainer {
      padding: 0;
      .row {
        width: 100%;
        margin: 0 auto;
        flex-direction: column;

        .col {
          width: 100%;
          margin: auto;

          form {
            max-width: 80%;
          }
        }

        .col:first-of-type {
          flex-basis: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Favorites {
    .table {
      th,
      p {
        font-size: 12px;
      }

      th,
      td {
        padding: 2px;
      }
    }
  }
}

@media only screen and (min-width: $basicScreenSize) {
  .Favorites {
    max-width: 1400px;
    margin: auto;
  }
}

// Tuotepainikkeiden keskitys
@media only screen and (max-width: 1259px) {
  .Favorites .favorites {
    max-width: calc(4 * 240px);
  }
}

@media only screen and (max-width: 1024px) {
  .Favorites .favorites {
    max-width: calc(4 * 240px);
  }
}

@media only screen and (max-width: 989px) {
  .Favorites .favorites {
    max-width: calc(3 * 240px);
  }
}

@media only screen and (max-width: 768px) {
  .Favorites .favorites {
    max-width: calc(4 * 172px);
  }
}

@media only screen and (max-width: 717px) {
  .Favorites .favorites {
    max-width: calc(3 * 172px);
  }
}

@media only screen and (max-width: 545px) {
  .Favorites .favorites {
    max-width: calc(2 * 172px);
  }
}

@media only screen and (max-width: 373px) {
  .Favorites .favorites {
    max-width: calc(1 * 172px);
  }
}
