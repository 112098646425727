.Order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #fff;

  .orderContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
    max-width: 1050px;
    padding: 30px;

    .orderCol1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      width: 50%;
      border-bottom: 1px solid #cdcdcd;
      padding: 15px;

      p {
        margin: 5px 0;
        text-align: left;
      }
    }

    .orderCol2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 auto;
      width: 50%;
      padding: 15px;

      p {
        margin: 5px 0;
        text-align: left;
      }

      .orderSubTitle {
        margin-top: 20px;
      }

      .orderSubTitle:first-of-type {
        margin-top: 5px;
      }
    }

    .orderCol3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      width: 100%;
      border-top: 1px solid #cdcdcd;
      padding: 15px;

      p {
        margin: 5px 0;
        text-align: right;
      }
    }

    .orderCol4 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      width: 100%;
      padding: 15px;

      p {
        margin: 5px 0;
        text-align: left;
      }

      .productsHeader {
        justify-content: space-between;
        align-items: flex-end;

        .copy-icon {
          fill: #007bff;
          stroke: #007bff;
          width: 18px;
          height: 22px;
          margin-left: 8px;
          transition: fill 0.15s ease-in-out, stroke 0.15s ease-in-out;
        }

        .btn:hover .copy-icon {
          fill: #fff;
          stroke: #fff;
        }
      }

      .orderProductsTitle {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        height: 38px;
        padding: 5px 0px;
        margin: 15px auto 5px auto;
        background-color: #7e7e7e;

        .col {
          h6 {
            margin: 6px 0;
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .orderItem {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        height: 100px;
        padding: 5px 0px;
        margin: 5px auto;
        border: 1px solid #dee2e6;
        border-radius: 0px;

        a {
          font-size: 14px;
          margin: auto;
          vertical-align: middle;
        }

        .col {
          display: flex;
          flex-direction: row;
          margin: auto;
          height: 88px;
          align-content: center;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          p {
            text-align: center;
          }

          img {
            height: 100%;
            width: auto;
          }
        }
      }
    }
  }

  h4 {
    margin: 0 auto 30px auto;
  }

  h6 {
    font-weight: 600;
    margin: 15px auto;
  }
}

@media only screen and (max-width: 1024px) {
  .Order {
    .orderContainer {
      padding: 15px 5px;

      .orderCol1,
      .orderCol2,
      .orderCol3 {
        width: 100%;
        padding: 10px;
      }
    }

    .checkoutTitle {
      width: 100%;
      max-width: 800px;

      h6 {
        font-size: 12px;
      }
    }

    .orderCol4 {
      padding: 15px 5px !important;

      .orderProductsTitle {
        width: 100%;
        max-width: 800px;

        .col img {
          max-width: 50px;
          height: auto !important;
          max-height: 86px;
        }

        .col h6 {
          font-size: 12px !important;
        }

        .col:last-of-type h6 {
          margin-top: 0;
        }
      }

      .orderItem {
        .col img {
          max-width: 50px;
          height: auto !important;
          max-height: 86px;
        }

        .col a,
        p,
        h6 {
          font-size: 12px !important;
        }
      }
    }
  }
}
