@import '../../variables';

.itemBtn {
  width: 222px;
  height: 400px;
  box-sizing: border-box;
  margin: 9px;

  .favoriteBtn,
  .deleteBtn {
    display: block;
    position: absolute;
    top: 314px;
    right: 5px;
    width: 20px;
    height: 20px;
    margin: auto;
    padding: 0 !important;
    border: 0 !important;
    background-color: transparent;

    img {
      margin-bottom: 5px;
      width: 20px;
      height: 20px;
    }
  }

  .favoriteBtn:disabled {
    opacity: 1 !important;
  }

  .favoriteBtn:hover {
    background-image: url('https://dc-collection.fi/images/icons/heart-solid-black.svg');
  }

  .deleteBtn:hover {
    background-image: url('https://dc-collection.fi/images/icons/circle-solid.svg');
  }

  .itemBtnLink {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #fff;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;

    .itemBtnImgContainer {
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 314.3px;
      overflow: hidden;

      img {
        height: 100%;
        width: auto;
        object-fit: cover;
      }
    }

    .ecoTag {
      display: block;
      position: absolute;
      bottom: 110px;
      left: 1px;
      min-width: 85px;
      height: 21px;
      background-color: $ecoGreen;

      p {
        font-size: 13px !important;
        letter-spacing: 1px;
        padding: 0 15px;
        color: #fff !important;
        font-weight: 500 !important;
      }
    }

    .ecoMoveUp {
      bottom: 131px !important;
    }

    .newTag {
      display: block;
      position: absolute;
      bottom: 110px;
      left: 1px;
      min-width: 85px;
      height: 21px;
      background-color: #000;

      p {
        font-size: 13px !important;
        letter-spacing: 1px;
        padding: 0 15px;
        color: #fff !important;
        font-weight: 500 !important;
      }
    }

    .outletTag {
      display: block;
      position: absolute;
      bottom: 110px;
      left: 1px;
      min-width: 85px;
      height: 21px;
      background-color: red;

      p {
        font-size: 13px !important;
        letter-spacing: 1px;
        padding: 0 15px;
        color: #fff !important;
        font-weight: 500 !important;
      }
    }

    p {
      font-size: 15px;
      margin: 0;
      color: #000;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-left: 2px;
      height: 44px;
      max-width: 100%;
      text-transform: uppercase;
      overflow: hidden;
    }

    p:first-of-type {
      font-size: 13px;
      font-weight: 400;
      margin: 5px 0 0 0;
      color: #6f6f6f;
      height: 19px;
    }

    p:last-of-type {
      height: 22px;
      font-size: 15px;
      font-weight: 400;
      display: inline;
      min-height: unset;
      margin: 0;
    }

    .strikethrough {
      text-decoration: line-through;
    }
  }
}

.itemBtn:hover {
  .itemBtnLink {
    text-decoration: none;
    border: 1px solid $gray;
  }
}

.favoriteButtonContainer {
  display: flex;
  flex-direction: column;

  .btn {
    width: 33%;
    margin: auto;
    font-size: 12px;
    margin-bottom: 40px;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .itemBtn {
    .ecoTag,
    .outletTag,
    .newTag {
      bottom: 130px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .itemBtn {
    width: 160px;
    height: 315px;
    box-sizing: border-box;
    margin: 6px !important;
    text-decoration: none;

    .itemBtnLink {
      border: 1px solid $gray;

      .itemBtnImgContainer {
        width: 158px;
        height: 228.3px;
      }

      p {
        font-size: 14px;
        overflow: hidden;
      }

      p:first-of-type {
        font-size: 12px;
      }

      p:last-of-type {
        font-size: 14px;
      }
    }

    .favoriteBtn,
    .deleteBtn {
      top: calc(314px - 85px);
      right: 6px;
      width: 18px;
      height: 18px;

      img {
        margin-bottom: 5px;
        width: 18px;
        height: 18px;
      }
    }
  }
}
