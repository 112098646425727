@import '../../variables';

.Collections {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: #fff;

  p {
    width: 100%;
    max-width: 750px;
    text-align: center;
    margin: 10px auto;
  }

  h4 {
    margin: 0 auto 30px auto;
  }

  .CollectionButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px auto;
    width: 100%;
    max-width: 1540px;

    div {
      display: flex;
      flex-direction: column;
      margin: 0 0 auto 0;
      text-align: center;
      width: 25%;
      padding: 30px;
      a {
        display: flex;
        flex-direction: column;
        margin: auto;
        text-align: center;

        img {
          height: 283px;
          width: 200px;
          object-fit: cover;
          margin: auto;
          transform: scale(1);
          -webkit-transition: all 0.2s;
          -moz-transition: all 0.2s;
          transition: all 0.2s;
        }
        h6 {
          margin-top: 15px;
          text-decoration: none;
          color: black;
          font-size: 16px;
          max-width: 200px;
        }
      }
      a:hover {
        color: black;
        img {
          transform: scale(1.025);
          box-shadow: $collectionShadow;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Collections {
    .CollectionButtonContainer {
      div {
        width: 50%;
        padding-left: 0;
        padding-right: 0;

        a h6 {
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .Collections {
    .CollectionButtonContainer {
      div {
        width: 100%;
      }
    }
  }
}
