@import '../../variables';

.Contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 50px;

  h4,
  h5 {
    margin: 0 auto 30px auto;
  }

  .contactAddress {
    width: 100%;
    max-width: 255px;
    text-align: center;
    margin: 30px auto;

    p {
      text-align: center;
      white-space: nowrap;
    }
  }

  hr {
    width: 100%;
    max-width: 500px;
    border-bottom: 1px solid #b6b6b6;
    margin: 0 auto 30px auto;
  }

  #contactFormSelector {
    width: 100%;
    max-width: 250px;
    margin: auto;

    .form-control {
      font-size: 14px;
    }
  }

  .contactForm {
    width: 100%;
    max-width: 450px;
    margin: auto;
    text-align: left;

    form {
      input,
      textarea {
        font-size: 14px;
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 4px;
        padding: 6px 12px;
      }

      label {
        font-size: 14px;
        margin-top: 16px;
        width: 100%;
      }

      .btn {
        display: flex;
        margin: 16px auto;
        text-align: center;
        padding: 10px 15px;
      }

      .validation-error {
        font-size: 14px;
        margin-top: 2px;
        color: $red;
        text-align: left;
      }

      p {
        font-size: 14px;
        margin: 16px auto;
        text-align: center;
      }
    }
  }
}
