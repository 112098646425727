.Received {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: #fff;

  h4 {
    margin: 0 auto;
  }

  h5 {
    margin: 30px auto;
  }

  hr {
    width: 100%;
    max-width: 1000px;
    border: 1px solid #aeaeae;
    margin: 0 auto;
  }

  p {
    margin: 0 auto;
  }

  .row {
    margin: 30px auto;

    .btn {
      margin: 0 30px;
    }
  }

  #contactUsRow {
    margin-bottom: 0;
  }

  #receivedCheckIcon {
    width: 125px;
    margin: 40px auto;
  }
}

@media only screen and (max-width: 1024px) {
  .Received {
    padding: 15px;

    .row .btn {
      margin: 0 15px;
    }
  }
}
