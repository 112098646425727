.Brands {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  text-align: left;
  margin: auto;

  .BrandsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
    margin: auto;

    p {
      text-align: left;
    }

    .p0 {
      font-weight: 700;
      margin-top: 20px;
    }
  }
}
