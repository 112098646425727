@import '../../variables';

.Checkout {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: #fff;

  form {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .form-col {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
    }

    .form-col:first-of-type {
      margin-right: 20px;
    }

    .form-col:last-of-type {
      margin-left: 20px;
      padding-top: 61px;
    }
  }

  input.disabled,
  select.disabled {
    background-color: $lightgray !important;
  }

  .mobilePriceRow {
    display: none;
    margin: 0 0 15px;
    border: 1px solid #c6c6c6;
    border-top: none;
    padding: 15px 0;
  }

  .validation-error {
    font-size: 14px;
    color: #dc3545;
    text-align: left;
    margin-bottom: 0;
  }

  h4 {
    margin: 0 auto 30px auto;
  }

  .checkoutItem {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    padding: 0px;
    margin: 5px auto;
    border: 1px solid #dee2e6;
    align-items: center;

    .strikethrough {
      text-decoration: line-through;
    }

    a {
      font-size: 14px;
      margin: 0;
      vertical-align: middle;
    }

    .checkoutCol {
      display: flex;
      flex-direction: row;
      margin: auto;
      height: 100%;
      width: 11%;
      align-content: center;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
        max-height: 80px;
        width: auto;
      }

      p {
        display: flex;
        flex-direction: column;
        margin: 0;
      }
    }

    .checkoutCol:first-of-type {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .checkoutCol:nth-of-type(2) {
      flex-direction: column;
      width: 36%;
      p {
        margin: 5px auto;
      }
    }

    .checkoutCol:nth-of-type(5) {
      width: 15%;
    }

    .checkoutCol:nth-of-type(7) {
      width: 5%;
    }

    .amountContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .amountContents {
        display: flex;
        flex-direction: row;
      }

      p {
        display: flex;
        margin: 17.5px 10px 0 0;
      }

      .plusMinusContainer {
        display: flex;
        flex-direction: column;

        .plusMinusBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          padding: 0px;
          padding-bottom: 1px;
          padding-right: 1px;
          max-height: 22px;
          height: 22px;
          max-width: 22px;
          width: 22px;
          margin: 3px auto;
          font-size: 16px;
          font-weight: 600;
          border: none;
        }

        .plusMinusBtn:focus {
          box-shadow: none;
        }
      }
    }

    .cartDeleteBtn {
      width: 100%;
      height: 30px;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      margin: auto 0;
      margin-left: -10px;
    }
  }

  .checkoutTitle {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 5px 0px;
    margin: 5px auto;
    background-color: #636363;

    .headerCol {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 11%;
      h6 {
        margin: 7px 0;
        color: #fff;
        font-size: 14px;
      }
    }

    .headerCol:nth-of-type(2) {
      width: 36%;
    }

    .headerCol:nth-of-type(5) {
      width: 15%;
    }
  }

  .checkoutTotal {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    margin: 25px auto 0 auto;
    padding: 0 10px;
  }

  div.cartTemplatesContainer {
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 48px 0 48px auto;

    #cart-file-title-button {
      font-size: 14px;
      border-color: #636363;
      margin: 0;
      color: black;
      font-weight: 400;
      padding: 4px 8px;
      border-radius: 6px;
      width: fit-content !important;
      min-width: 180px;

      &:hover {
        cursor: pointer;
        color: white;
      }

      &:focus {
        box-shadow: none !important;
      }
    }

    .btn {
      margin: 12px 0 0 0;
    }

    form#upload-cart-file {
      #cart-file-input {
        color: transparent !important;
        padding: 0;
        height: 29px;
        width: 125px;
        border-color: transparent;
      }

      .form-file-label {
        margin: 0 0 10px 0;
      }

      #cart-file-input::after {
        content: "";
      }
      
      #cart-file-input::before {
        content: "";
      }
    }
  }

  h5.addressTitle {
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 30px auto 10px auto;
    padding: 0 10px;
  }

  #paymentTerm {
    text-align: left;
  }

  .paymentTerms {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 30px auto 10px auto;
    padding: 0 10px;
  }

  .checkoutTerms {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin: 0 auto 15px;

    .form-check {
      padding-left: 0px !important;
    }

    .form-group {
      margin: 0;
      max-width: 400px;

      p {
        margin: 0 auto;
      }

      label {
        margin-left: 0;
        padding-left: 30px;
      }
    }

    input[type='checkbox'] {
      -ms-transform: scale(1.5); /* IE */
      -moz-transform: scale(1.5); /* FF */
      -webkit-transform: scale(1.5); /* Safari and Chrome */
      -o-transform: scale(1.5); /* Opera */
      transform: scale(1.5);
      padding: 5px;
      margin-left: 7px;
      margin-top: 24px;
      width: auto;
      height: auto;
    }
  }

  .checkoutInformation,
  .userPaymentInformation {
    margin: 0;
    max-width: 400px;
    text-align: left;
    p {
      margin: 5px auto 5px 5px;
    }
  }

  .buyButton {
    cursor: pointer;
    width: 100%;
    max-width: 400px;
    min-width: 150px;
    background-color: #212529;
    border-color: #212529;
    color: #fff;
    margin: 0 0 30px;
    padding: 15px;
    font-weight: bolder;

    .spinner-border {
      margin-left: 5px;
      margin-bottom: 2px;
    }
  }

  .buyButton.disabledButton {
    cursor: not-allowed;
  }

  hr {
    width: 100%;
    border: 1px solid #636363;
    margin: 0 auto;
  }

  .deliveryMethod,
  .form-group {
    width: 100%;
    max-width: 400px;
    padding: 0;
    margin: 0;
    font-size: 14px;

    .infoIcon {
      height: 18px;
      width: auto;
      margin-bottom: 0px;
      margin-left: 0px;
      background-color: white;
      border-radius: 50%;
    }

    input,
    select {
      width: 100%;
      font-size: 14px;
      margin-bottom: 20px;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #cdcdcd;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: calc(1.5em + 0.75rem + 2px);
      margin-bottom: 0px;
      font-weight: 400;
      text-align: left;
      color: #212529;
      padding: 5px 10px;
    }
  }

  label {
    margin: 20px auto 5px 5px;
    width: 100%;
    max-width: 400px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
}

.disabledButton {
  cursor: not-allowed;
}

.deliveryAddress.form-group {
  margin-top: 0;
  margin-bottom: 30px;
}

#orderMsg {
  width: 100%;
  max-width: 400px;
  padding: 0;
  margin: 0;
  font-size: 14px;

  textarea {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    padding: 5px 10px;
    width: 100%;
  }

  .form-control {
    font-size: 14px;
    text-align: center;
  }
}

.saveToAddressList {
  padding-left: 0 !important;

  input[type='checkbox'] {
    margin-left: 5px;
    margin-top: 24px;
    width: auto;
    height: auto;
  }

  label {
    font-weight: 400 !important;
    margin-left: 0 !important;
    padding-left: 25px !important;
  }
}

.checkoutModal {
  .modal-body {
    padding: 1rem 2rem;
  }

  .modal-body .modal-title.h4 {
    margin: 10px auto 20px;
    text-align: center;
  }

  .modal-body p {
    margin: 20px auto;
    text-align: center;
  }

  .checkoutModalButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;

    .form-group {
      width: max-content;
      margin: 0px auto;
      font-size: 14px;

      .form-check {
        margin: 15px auto;
      }
    }

    div {
      width: max-content;
      margin: 10px auto;
      font-size: 14px;

      .btn {
        margin: 0 10px;
        padding: 10px 20px;
      }
    }
  }

  .checkoutModalItem {
    display: flex;
    flex-direction: row;
    border: 1px solid #dee2e6;
    text-align: center;
    margin: 10px auto;
    width: 100%;

    .section {
      display: flex;
      flex-direction: column;
      padding: 5px;
      max-height: 160px;
    }

    .section:first-of-type {
      width: 30% !important;
      img {
        width: 100%;
        max-width: 90px;
        height: auto;
        max-height: 135px;
        margin: auto;
      }
    }

    .section:last-of-type {
      width: 70% !important;
      justify-content: space-evenly;

      p {
        display: flex;
        font-size: 14px;
        margin: 0px auto;
      }

      p:first-of-type {
        font-weight: bolder;
      }
    }
  }
}

.tooltipInfoTextCheckout {

  .tooltip-inner {
    max-width: 90vw;
    width: 510px;
    padding: 8px;

    span {
      font-size: 13px;
    }
  }
}



@media only screen and (max-width: 1024px) {

  .tooltipInfoTextCheckout.tooltip.show {
    display: block;
  }

  .Checkout {
    padding: 15px;

    .checkoutTitle {
      .col h6 {
        font-size: 13px;
      }

      h6 {
        font-size: 12px;
      }
    }

    .checkoutItem {
      .checkoutCol a,
      p {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Checkout {

    div.cartTemplatesContainer {
      display: none;
    }

    .checkoutTotal {
      justify-content: flex-end;
    }

    form {
      flex-direction: column;
  
      .form-col {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
      }
  
      .form-col:first-of-type {
        margin: auto;
      }
  
      .form-col:last-of-type {
        margin: auto;
        padding-top: 0;
      }
    }

    .checkoutTitle {
      display: none;
    }

    .checkoutItem {
      margin-bottom: 0;
      border: 1px solid #c6c6c6;
      border-bottom: none;
      padding-top: 15px;

      .checkoutCol:nth-of-type(4),
      .checkoutCol:nth-of-type(5),
      .checkoutCol:nth-of-type(6) {
        display: none;
      }

      .checkoutCol:nth-of-type(7) {
        display: block;
        position: relative;
        top: -16px;
        right: 26px;
        width: 0px;

        .btn {
          display: block;
          position: relative;
          width: 27px;
          padding: 2px 5px;
        }
      }

      .checkoutCol:nth-of-type(1) {
        width: 25%;
      }

      .checkoutCol:nth-of-type(2) {
        width: 50%;
      }

      .checkoutCol:nth-of-type(3) {
        width: 25%;
      }
    }
    .mobilePriceRow {
      display: flex;
      flex-direction: row;

      .checkoutCol {
        display: flex;
        flex-direction: column;
        margin: auto;
        height: 100%;
        align-content: center;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        p {
          font-size: 12px;
          margin: 6px auto;
        }

        .strikethrough {
          text-decoration: line-through;
        }
      }

      .checkoutCol:nth-of-type(1),
      .checkoutCol:nth-of-type(3) {
        width: 25%;
      }

      .checkoutCol:nth-of-type(2) {
        width: 50%;
      }
    }
  }
}

@media only screen and (min-width: $basicScreenSize) {
  .Checkout {
    justify-content: center;
    max-width: 1280px;
    margin-left: calc((100vw - 1280px) / 2);
  }
}
