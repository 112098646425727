.card-editor {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 16px 100px 16px;
    background-color: #dedede;
    min-height: 100vh;
    min-width: fit-content;

    .button-container {
        display: flex;
        justify-content: center;
        width: auto;
        margin: 20px;

        .save-pdf {
            display: flex;
            width: fit-content;
            font-size: 16px;
            font-weight: 700;
            padding: 10px 20px;
            margin: 0;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            white-space: nowrap;
        }
    }
}