@import '../../variables';

.Footer {
  display: flex;
  flex-direction: column;
  background-color: #000;

  .carousel-container {
    .react-multiple-carousel__arrow {
      border: 1px solid transparent;
    }
    .react-multiple-carousel__arrow:hover {
      border: 1px solid #000;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: #fff;
    background-color: $gray;
    padding: 12px 0;
    margin: 0;
    font-size: 13px;
  }

  .react-multi-carousel-list {
    background-color: #fff;
  }

  .footerContainer {
    width: 100%;
    padding: 24px 10%;
    display: flex;
    flex-direction: row;
    max-width: 1540px;
    margin: auto;

    .col {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: fit-content;
        margin: 0 auto;

        h6 {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
        }

        a {
          color: #fff;
          font-size: 13px;

          p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            color: #fff;
            background-color: #000;
            padding: 0;
            margin: 5px 0 0 0;
            font-size: 13px;
          }
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Footer .footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    text-align: center;
    padding: 20px 10px;
    min-height: 320px;

    .col {
      justify-content: flex-start;
      margin: 10px auto;
      min-height: 100px;
      height: auto;

      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}
