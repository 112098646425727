@import '../../variables';

.scrollToTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 980;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.8;
  background-color: $gray;
}

.scrollToTop:hover {
  opacity: 1;
}

.scrollToTop:active,
.scrollToTop:focus {
  opacity: 0.8;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

@media only screen and (min-width: 1025px) {
  .scrollToTop {
    display: none !important;
    opacity: 0;
  }
  .scrollToTop:hover {
    opacity: 0;
  }
}
