.Cpdp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  text-align: left;
  margin: auto;

  .DataProtection {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
    margin: auto;
    text-align: left;
    font-size: 14px;

    p {
      text-align: left;
    }

    p.p1 {
      color: #000000;
    }

    p.p2 {
      color: #000000;
      min-height: 15px;
    }

    p.p3 {
      color: #000000;
    }

    li.li3 {
      margin: 0px 0px 5px 0px;
      color: #000000;
    }

    span.s1 {
      font-kerning: none;
    }

    span.s3 {
      text-decoration: underline;
      font-kerning: none;
      color: #0b4cb4;
    }

    ul.ul1 {
      list-style-type: disc;
    }
  }

  h4 {
    margin: 15px auto;
  }

  h5 {
    margin-top: 30px;
  }

  p {
    width: 100%;
  }
}
