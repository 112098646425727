$gray: #636363;
$lightgray: #dee2e6;
$blue: #007bff;
$lightblue: #5aaaff;
$red: #dc3545;
$shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.3);
$mobileFilterShadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
$navShadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.15);
$collectionShadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.35);
$bgGray: #eaeaea;
$ecoGreen: #56c120;
$basicScreenSize: 1280px;
