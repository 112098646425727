@import '../../variables';

.image-editor {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 15px 15px 15px;
    background-color: #dedede;
    min-height: 100vh;
    min-width: 100%;
    width: auto;
    height: auto;

    h2 {
        font-size: 20px !important;
    }
}

div.image-template {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 1400px;
    height: 100%;
    min-width: 1400px;
    margin: 0 auto;
    border-radius: 0 0 16px 16px;

    /* Selainten yhdenmukaisuus parannuksia */
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
    image-rendering: auto !important;

    div.image-editor-product-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 16px 8px;
    border-bottom: 1px solid #afafaf;
    height: auto;

        img {
            width: auto;
            height: auto;
            max-height: 40px;
            max-width: 200px;
        }

        div:not(.image-editor-title-col) {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-left: 16px;

            h1 {
                font-size: 20px;
                text-transform: uppercase;
                margin: auto 0;
                text-align: left;
                line-height: 20px;
            }

            span {
                display: flex;
                text-align: left;
                color: #505050;
                margin-left: 8px;
            }
        }

        div.image-editor-title-col {
            display: flex;
            flex-direction: row;

            h2#dc-collection {
                font-size: 22px !important;
                margin: 0;
                font-family: Arial, Helvetica, sans-serif;
                white-space: nowrap;
            }
        }
    }

    div.image-editor-container-cols {
        display: flex;
        flex-direction: row;
    }

    div.image-editor-col {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 12px 16px;
        overflow: hidden;

        div.image-editor-select-color {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 16px 0;
            border-bottom: 1px solid #afafaf;

            h2 {
                text-align: left;
                padding: 0 8px;
            }

            div.image-editor-colorbuttons {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                    .colorButton {
                        display: flex;
                        flex-direction: column;
                        width: 52px;
                        height: 52px;
                        padding: 4px;
                        margin: 4px !important;
                        border: 1px solid #fff;
                        border-radius: 50px;
                        border: 1px solid #dedede;

                        img {
                        margin: 0;
                        height: 100%;
                        width: auto;
                        border-radius: 50px;
                        object-fit: cover;
                        }

                        &:hover {
                            cursor: pointer;
                            border: 1px solid $lightblue;
                            -webkit-box-shadow: 0px 0px 1px 1px $lightblue;
                            -moz-box-shadow: 0px 0px 1px 1px $lightblue;
                            box-shadow: 0px 0px 1px 1px $lightblue;
                        }
                    }

        .activeColor {
            -webkit-box-shadow: 0px 0px 3px 1px #000;
            -moz-box-shadow: 0px 0px 3px 1px #000;
            box-shadow: 0px 0px 3px 1px #000;
        }

        .activeColor:hover {
        cursor: default !important;
        border: 1px solid #fff !important;
        -webkit-box-shadow: 0px 0px 3px 1px #000 !important;
        -moz-box-shadow: 0px 0px 3px 1px #000 !important;
        box-shadow: 0px 0px 3px 1px #000 !important;
        }
        }
        }

        div.image-editor-text-editor {
            display: flex;
            flex-direction: column;
            padding: 16px 0;
            border-bottom: 1px solid #afafaf;

            h2 {
                text-align: left;
                padding: 0 8px 8px 8px;
            }

            p {
                text-align: left;
                padding: 0 8px;
                margin: 0 0 4px;
                font-size: 14px;
            }

            button.btn {
                margin: 16px auto 0 8px;
                padding: 8px 16px;
                border-radius: 6px;
                font-weight: bold;

                svg {
                    font-size: 18px;
                    margin-bottom: 3px;
                    margin-left: 6px;
                }
            }
        }

        div.image-editor-upload-image {
            display: flex;
            flex-direction: column;
            padding: 16px 0;

            h2 {
                text-align: left;
                padding: 0 8px 8px 8px;
            }

            p {
                text-align: left;
                padding: 0 8px;
                margin: 0 0 4px;
                font-size: 14px;
            }

            button.btn {
                margin: 16px auto 0 8px;
                padding: 8px 16px;
                border-radius: 6px;
                font-weight: bold;
            }

            div.drop-zone {
                background-color: #e0e0e0;
                color: #323232;
                margin: 16px auto 0 8px;
                padding: 16px;
                border-radius: 10px;
                cursor: pointer;

                svg {
                    color: #323232e5;
                    font-size: 20px;
                }
            }
        }

        div.image-editor-select-image {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 16px 0;
            border-bottom: 1px solid #afafaf;

            h2 {
                text-align: left;
                margin: 0 auto 0.5rem 0;
                padding: 0 8px;
                white-space: nowrap;
            }

        div.image-editor-imagebuttons {
            display: flex;
            flex-direction: row;
            margin: 0;
            padding: 5px;
            width: fit-content;
            height: 80px;
            overflow: hidden;

            img {
                border: 1px solid #dedede;
                margin: 0 6px;

                &:hover {
                cursor: pointer;
                border: 1px solid $lightblue;
                -webkit-box-shadow: 0px 0px 1px 1px $lightblue;
                -moz-box-shadow: 0px 0px 1px 1px $lightblue;
                box-shadow: 0px 0px 1px 1px $lightblue;
                }
            }

        }
        }

        div.image-editor-layer-tools {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: auto;
            min-height: 68px;

            h6 {
                font-weight: 600;
                margin: 0;
            }

            div.layer-tools-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 100%;
            }

            div.tool-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 44px !important;
                padding-bottom: 6px;

                &.diameter-row {
                    padding-bottom: 0;

                    > div:first-of-type {
                        margin-left: 5px;
                    }
                }

                > div {
                    display: flex;
                    flex-direction: column;

                    &:first-of-type {
                        margin-left: 10px;
                    }
                }

                span.text {
                    margin-left: 5px;
                }

                button.btn {
                    margin: 0;
                    border-radius: 6px;
                    margin: 0 0 0 10px;
                    padding: 3px 8px;
                    white-space: nowrap;

                    svg {
                        font-size: 14px;
                        margin-bottom: 3px;
                    }
                }
    
                label {
                    font-size: 14px;
                    margin: 0 4px 0 0;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;

                    &#diameter-label {
                        margin: 0 auto;
                    }
                }

                div.diameter-container {
                    display: flex;
                    flex-direction: column;

                    > div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 auto;

                        span {
                            display: flex;
                            font-size: 14px;
    
                            &:first-of-type {
                                margin: 0 5px 0 0px;
                            }
    
                            &:last-of-type {
                                margin: 0 10px 0 5px;
                            }
                        }

                        label {
                            display: flex;
                            font-size: 14px;
                        }

                        button.zoom-button {
                            display: flex;
                            width: 24px;
                            height: 24px;
                            padding: 4px;
                            border-radius: 50%;
                            border: 1px solid #afafaf;
        
                            &:hover {
                                border: 1px solid #5aaaff;
                                box-shadow: 0px 0px 1px 1px #5aaaff;
                            }
                        }
                    }

                }
    
                input {
                    font-size: 14px;
                    max-width: 60px;
                    max-height: 27px;
                    margin-right: 15px;

                    &[type="range"] {
                        min-width: 150px;

                        &.width-200 {
                            min-width: 200px;
                        }

                        &#diameter {
                            min-width: 600px;
                            margin: 0 5px;

                            &::-webkit-slider-runnable-track {
                                background: #EFEFEF;
                                border: 1px solid #B2B2B2;
                                border-radius: 30px;
                            }
                            
                            &::-moz-range-track {
                                background: #EFEFEF;
                                border: 1px solid #B2B2B2;
                                border-radius: 30px;
                            }
                            
                            &::-ms-track {
                                background: #EFEFEF;
                                border: 1px solid #B2B2B2;
                                border-radius: 30px;
                                color: transparent; /* Needed for IE */
                            }

                            &::-webkit-slider-runnable-track {
                                background: #EFEFEF;
                            }
                            
                            &::-moz-range-progress {
                                background: #EFEFEF;
                            }
                            
                            &::-ms-fill-lower {
                                background: #EFEFEF;
                            }
                            
                            // &::-webkit-slider-thumb {
                            //     -webkit-appearance: none;
                            // }
                            
                            // &::-moz-range-thumb {
                            //     -moz-appearance: none;
                            // }
                            
                            // &::-ms-thumb {
                            //     appearance: none;
                            // }
                        }
                    }

                }

                button.text-tool-btn {
                    border-radius: 6px;
                    font-size: 14px;
                    line-height: 24px;
                    border: 1px solid #afafaf;
                    margin-right: 10px;
                    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.12);
                    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.12);
                    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.12);
                    white-space: nowrap;

                    &.active {
                        border-color: #6c757d;
                        background-color: rgb(180, 180, 180);
                        -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.6);
                        -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.6);
                        box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.6);
                    }

                    &.margin-left {
                        margin-left: 22px;
                    }

                    &.red {
                        background-color: #ff4545;
                        border-color: #be0000;
                    }

                    &.text-tool-color-picker {
                        width: 48px;
                        height: 28px;
                        border-radius: 6px;
                        border: 1px solid #afafaf;

                        .color-frame {
                            margin: 0 auto;
                            width: 34px;
                            height: 16px;
                            border-radius: 2px;
                            background-color: black;
                            outline: 1px solid #afafaf;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    div.custom-icon {
                        width: 16px;
                        height: auto;

                        img#stroke-icon {
                            width: 12px;
                            height: 12px;
                        }
                    }

                }

                select {
                    font-size: 14px;
                    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
                    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
                    margin-right: 10px;
                }
            }
        }

        div.image-editor-editable-image {
            display: flex;
            position: relative;
            width: 668px !important;
            height: 668px !important;
            margin: 12px 0 0 0;
            outline: 1px solid #afafaf;
            overflow: hidden;
            z-index: 2;

            div.canvas-container {
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;

                #editor-canvas-0,
                #editor-canvas-1,
                #editor-canvas-2 {
                    position: relative;
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            img.editable-image-main-layer {
                display: flex;
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 100%;
                width: auto;
                max-height: 100%;
                height: auto;
            }

            // div.scale-handle {
            //     display: flex;
            //     position: absolute;
            //     bottom: 0;
            //     right: 0;

            //     svg {
            //         transform: rotate(90deg);
            //         opacity: 0;
            //     }

            //     &:hover {
            //         cursor: se-resize !important;
            //     }
            // }

            div.image-editor-text-layer {
                position: absolute;
                z-index: 1;
                width: max-content;
                text-align: left;

                p {
                    margin: 0;
                    line-height: 1;
                    font-family: Helvetica,Arial,sans-serif;
                    font-size: 24px;
                    color: black;
                }
            }

            div.image-editor-image-layer {
                position: absolute;
                z-index: 1;
                max-width: 100%;
                width: auto;
                max-height: 100%;
                height: auto;

                // &:hover div.scale-handle > svg {
                //     opacity: 1;
                // }

                p {
                    margin: 0;
                }

                img {
                    max-width: 100%;
                    width: auto;
                    max-height: 100%;
                    height: auto;
                }
            }

            span.angle-info {
                position: absolute;
                top: 0;
                left: 0;
                padding-top: 6px;
                padding-left: 6px;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 16px;
                color: black;
            }

            div.zoom-container {
                position: absolute;
                display: flex;
                flex-direction: row;
                align-items: center;
                left: 7px;
                bottom: 7px;
                opacity: 0.67;

                button.zoom-button {
                    display: flex;
                    width: 24px;
                    height: 24px;
                    padding: 4px;
                    border-radius: 50%;
                    border: 1px solid #afafaf;

                    &:hover {
                        border: 1px solid #5aaaff;
                        box-shadow: 0px 0px 1px 1px #5aaaff;
                    }
                }
    
                span#zoom-value {
                    opacity: 0.75;
                    display: flex;
                    font-size: 13px;
                    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
                    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
                    line-height: 15px;
                    margin: auto;
                    padding: 0 5px;
                }

                &:hover {
                    opacity: 1;
                }
            }

        }



        div.image-editor-download-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            height: 58px;
            margin: 0;

            div.canvas-switches {
                display: flex;
                width: fit-content;
                margin-left: 2px;
                z-index: 1;

                > div {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
    
                    button.canvas-switch-button {
                        font-size: 14px;
                        line-height: 24px;
                        border: 1px solid #afafaf;
                        border-top: none;
                        margin: 0;
                        padding: 4px 12px;
                        width: 71px;
        
                        &.first {
                            border-bottom-left-radius: 6px;
                        }
        
                        &.last {
                            border-bottom-right-radius: 6px;
                        }
        
                        &:hover:not(.active) {
                            border-color: #5aaaff;
                        }
        
                        &.active {
                            font-weight: bold;
                            background-color: #b4b4b4;
        
                            &:hover {
                                cursor: default;
                            }
                        }
                    }

                    input {
                        margin: 8px auto 0;
                    }
                }

            }

            div.big-blue-buttons {
                display: flex;
                flex-direction: row;

                button.btn {
                    margin: 15px 0px auto 12px;
                    padding: 8px 15px;
                    border-radius: 6px;
                    font-weight: bold;
                    font-size: 15px;
                    white-space: nowrap;
    
                    svg {
                        font-size: 20px;
                        margin-bottom: 3px;
                        margin-left: 7px;
                    }
                }
            }

        }
    }
}

div.hidden-editor-duplicate {
    position: absolute;
    z-index: 1;
    width: max-content;
    text-align: left;

    p {
        margin: 0;
        line-height: 1;
        font-family: Helvetica,Arial,sans-serif;
        font-size: 24px;
        color: black;
    }
}

#editor-text-color-picker {
    padding: 0;
    border: none;

    .popover-body {
        padding: 0;
    }

    .arrow {
    display: none;
}   
}
