@import '../../variables';

#mainBannerCarousel {
  background-color: #fff;

  .carousel-inner:hover {
    cursor: pointer;
  }

  .carousel-inner {
    .carousel-item img {
      width: 1220px;
      height: auto;
    }
  }

  .carousel-indicators {
    margin-bottom: calc(1rem + 30px);
  }

  .carousel-indicators li {
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 6px !important;
    border-top: 10px solid rgba(0, 0, 0, 0.5) !important;
    border-bottom: 10px solid rgba(0, 0, 0, 0.5) !important;
    border-left: 10px solid rgba(0, 0, 0, 0.5) !important;
    border-right: 10px solid rgba(0, 0, 0, 0.5) !important;
  }

  .carousel-indicators li:hover {
    border-top: 10px solid rgba(0, 0, 0, 0.9) !important;
    border-bottom: 10px solid rgba(0, 0, 0, 0.9) !important;
    border-left: 10px solid rgba(0, 0, 0, 0.9) !important;
    border-right: 10px solid rgba(0, 0, 0, 0.9) !important;
  }

  .carousel-control-next {
    right: 30px;
    top: 30px;
    height: calc(100% - 60px);
  }

  .carousel-control-prev {
    left: 30px;
    top: 30px;
    height: calc(100% - 60px);
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #000000 !important;
    border: 10px solid #000000 !important;
    border-radius: 250% !important;
    width: 36px !important;
    height: 36px !important;
  }
}

@media only screen and (max-width: 1024px) {
  #mainBannerCarousel {
    padding: 0;

    .carousel-inner {
      .carousel-item img {
        width: 100%;
        height: auto;
      }
    }

    .carousel-indicators {
      margin-bottom: 1rem;
    }

    .carousel-indicators li {
      height: 3px !important;
      border-top: 5px solid rgba(0, 0, 0, 0.5) !important;
      border-bottom: 5px solid rgba(0, 0, 0, 0.5) !important;
      border-left: 5px solid rgba(0, 0, 0, 0.5) !important;
      border-right: 5px solid rgba(0, 0, 0, 0.5) !important;
    }

    .carousel-indicators li:hover {
      border-top: 5px solid rgba(0, 0, 0, 0.9) !important;
      border-bottom: 5px solid rgba(0, 0, 0, 0.9) !important;
      border-left: 5px solid rgba(0, 0, 0, 0.9) !important;
      border-right: 5px solid rgba(0, 0, 0, 0.9) !important;
    }

    .carousel-control-next {
      right: 0px;
    }

    .carousel-control-prev {
      left: 0px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: #000000 !important;
      border: 8px solid #000000 !important;
      border-radius: 250% !important;
      width: 28px !important;
      height: 28px !important;
    }
  }
}

@media only screen and (min-width: $basicScreenSize) {
  #mainBannerCarousel {
    .carousel-control-prev,
    .carousel-control-next {
      width: 5%;
    }
  }
}
