.notFoundContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 50px;
  margin-top: 1rem;

  h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  img {
    margin-bottom: 2rem;
  }

  p {
    font-size: 16px;
    margin: 2rem 0 4rem 0;
  }
}
