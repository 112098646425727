.ResetPw {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 780px;
    min-height: 400px;
    margin: auto;
    padding: 30px;
    background-color: #fff;

    h4 {
        margin: 15px auto;
    }

    form {
        width: 100%;
        max-width: 420px;
        margin: 15px auto;
        font-size: 14px;

        label {
            margin-top: 15px;
            text-align: left;
            width: 100%;
        }

        input {
        font-size: 14px;
        }

        .btn {
            padding: 8px;
          }
  
          .pw-eye {
            padding: 8px;
  
            img {
              width: 18px;
              height: auto;
            }
          }
  
          .pw-eye:focus {
            outline: none;
            box-shadow: none;
          }
    }
}
