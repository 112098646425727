@import '../../variables';

.ItemBrowse {
  display: flex;
  flex-direction: column;
  padding: 0;

  .topBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    background-color: #fff;
    padding: 15px 0 30px 0;

    h1 {
      font-size: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
    }
  }
}

.Campaign {
  max-width: 1540px;
  margin-left: calc((100vw - 1540px) / 2);
  .topCampaign {
    width: 100%;
    padding: 0 0px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 180px;
    }

    h1 {
      font-size: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      padding: 15px;
    }
  }
}

.categoryContainer {
  display: flex;
  width: 100%;
  min-height: 876px;
  padding: 0 !important;
  background-color: #fff;

  .categoryCol1 {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    padding: 0px 0 15px 15px;

    h6 {
      margin-top: 8px;
      margin-bottom: 20px;
      justify-content: center;
    }

    p {
      margin-bottom: 25px;
    }

    .filterBox {
      padding: 15px;

      .btn {
        margin: 10px auto auto auto;
      }

      .form-label {
        width: 90%;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .recentlyViewedBox {
      padding: 15px;
      margin-top: 30px;

      .recentlyViewedButtons {
        a .recentContainer {
          max-height: 100px;
          display: flex;
          flex-direction: row;
          border: 1px solid #fff;
          background-color: #fff;
          // border-radius: 5px;
          padding: 5px;
          margin-top: 15px;

          p {
            margin: 4px auto;
            color: #000;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 12px;
          }

          p:first-of-type {
            font-size: 10px;
            font-weight: 400;
            height: 16px;
            min-height: unset;
            margin: 5px auto;
            color: #787878;
          }

          p:last-of-type {
            display: inline;
            min-height: unset;
            margin: 0 auto;
          }

          .recentColOne {
            display: flex;
            flex-direction: column;
            width: 40%;

            img {
              margin: auto;
              width: auto;
              max-width: 56px;
            }
          }

          .recentColTwo {
            display: flex;
            flex-direction: column;
            width: 60%;
          }
        }
      }

      .recentBtnLink:hover {
        text-decoration: none;
        .recentContainer {
          border: 1px solid $gray;
        }
      }
    }
  }

  .categoryCol2 {
    display: flex;
    flex-direction: column;
    width: 80%;

    .topBox2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-height: 70px;
      padding: 15px;
      margin-left: 15px;
      margin-right: 15px;

      .sortForm {
        display: inline-flex;
        margin: auto;
        justify-content: center;
        align-items: center;
        font-size: 14px;

        label {
          margin: auto;
          margin-right: 5px;
        }

        .form-control {
          padding: 2px;
          font-size: 14px;
        }
      }

      .pagination {
        font-size: 14px !important;
        margin: auto;

        .page-item.active .page-link {
          border: none;
          background-color: $gray;
          color: #fff;
        }

        p {
          margin: auto 10px auto 0;
        }

        .page-link {
          border: none;
          border-radius: 0px;
          min-width: 33px;
          color: #000;
        }

        .page-link:focus {
          box-shadow: none;
        }
      }

      p {
        font-size: 14px;
        display: flex;
        margin: auto;
      }
    }

    .productsBox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 15px;
      margin: 0 auto;

      .pagination {
        font-size: 14px !important;
        margin: auto;
        margin-top: 2rem;
        width: 100%;
        justify-content: center;

        p {
          margin: auto 10px auto 0;
        }

        .page-item.active .page-link {
          border: none;
          background-color: $gray;
          color: #fff;
        }

        .page-link {
          border: none;
          border-radius: 0px;
          min-width: 33px;
          color: #000;
        }

        .page-link:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.filterTitle {
  border: 1px solid $lightgray;
  padding: 6px;
  margin: 5px auto;

  label {
    margin: auto;
  }
}

.filterTitle:hover {
  cursor: pointer;
}

.filterTitle label:hover {
  cursor: pointer;
}

.filterTitle img:hover {
  cursor: pointer;
}

.filterAmount {
  color: #000;
  font-size: 12px;
  padding: 4.25px 8px;
  min-width: 50px;
  border-radius: 50px;
  background-color: $lightgray;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  margin-left: 2px;
}

.filterCheckBoxes {
  margin: auto;
  padding-bottom: 2px;
  width: 85%;
  font-size: 14px;

  .form-check {
    text-align: left;
  }
}

.rotate180 {
  transform: rotate(180deg);
}

@media only screen and (max-width: 1024px) {
  .mobile-only {
    display: flex;
  }

  .ItemBrowse {
    nav .breadcrumb {
      padding: 15px 10px 10px 10px;
    }

    .topBox {
      padding: 0px 10px 15px 10px !important;
      margin-bottom: 0 !important;

      h1 {
        font-size: 20px !important;
        font-weight: 700;
      }

      img {
        height: auto !important;
      }
    }

    .categoryContainer {
      .mobile-filter {
        width: 100%;
        height: 100%;
        position: fixed; /* Stay in place */
        z-index: 9999; /* Sit on top */
        left: 0;
        top: 0;
        background-color: #fff; /* Black w/opacity */
        overflow-x: hidden; /* Disable horizontal scroll */
        transition: 0.5s;
        padding: 15px 25px;

        .filterBox {
          position: relative;
          top: 0; /* 25% from the top */
          width: 100%; /* 100% width */
          text-align: center; /* Centered text/links */
          font-size: 14px !important;

          .form-check-input {
            width: 18px;
            height: 18px;
            margin-top: 7px;
          }

          label {
            font-size: 14px;
            padding: 4px;
          }

          .filterCheckBoxes p {
            font-size: 14px;
          }

          .lockedFilter {
            width: 100% !important;
          }
        }

        .mobile-filter-buttons {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50px;
          z-index: 999;

          .btn {
            font-size: 13px;
            width: 50%;
          }
        }
      }

      .categoryCol1 {
        display: none;
      }

      .categoryCol2 {
        width: 100%;
        padding-bottom: 75px;

        .topBox2 {
          width: 100%;
          max-height: unset;
          height: 45px;
          margin: auto;
          padding: 0;
          position: sticky;
          top: 44px;
          box-shadow: $mobileFilterShadow;
          z-index: 980;

          .mobile-filter-toggle {
            display: flex;
            width: 50%;
            height: 45px;
            background-color: #fff;
            border: none;
            border: 1px solid $lightgray;
            border-left: none;
            padding: 5px;
            text-align: left;
            justify-content: center;
            align-items: center;
            font-size: 13px;
          }

          .mobile-filter-toggle:focus,
          .sortForm select:hover {
            box-shadow: none;
          }

          p:first-of-type {
            display: none;
          }

          .sortForm {
            width: 50%;
            height: 45px;
            padding: 0;
            border: 1px solid $lightgray;
            border-left: none;
            border-right: none;

            label {
              display: none;
            }

            select {
              height: 100%;
              padding: 5px;
              border: none;
              border-radius: 0;
              color: #000;
              text-transform: uppercase;
              font-size: 13px;
            }
          }

          p,
          div,
          ul {
            padding: 10px 0;
          }

          p {
            padding-top: 20px;
          }

          ul {
            padding-bottom: 20px;
          }

          .pagination {
            display: none;
          }
        }

        .extra-topping-87 {
          top: 87px !important;
        }

        .productsBox {
          .pagination {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $basicScreenSize) {
  .ItemBrowse {
    justify-content: center;
    max-width: 1280px;
    margin-left: calc((100vw - 1280px) / 2);
  }
}

@media only screen and (min-width: 1540px) {
  .ItemBrowse {
    max-width: 1540px;
    margin-left: calc((100vw - 1540px) / 2);
  }
}

// Tuotepainikkeiden keskitys
@media only screen and (max-width: 1539px) {
  .categoryContainer .categoryCol2 .productsBox {
    max-width: calc(4 * 240px + 30px);
  }
}

@media only screen and (max-width: 1237px) {
  .categoryContainer .categoryCol2 .productsBox {
    max-width: calc(3 * 240px + 30px);
  }
}

@media only screen and (max-width: 1024px) {
  .categoryContainer .categoryCol2 .productsBox {
    max-width: calc(4 * 240px + 30px);
  }
}

@media only screen and (max-width: 989px) {
  .categoryContainer .categoryCol2 .productsBox {
    max-width: calc(3 * 240px + 30px);
  }
}

@media only screen and (max-width: 768px) {
  .categoryContainer .categoryCol2 .productsBox {
    max-width: calc(4 * 172px + 30px);
  }
}

@media only screen and (max-width: 717px) {
  .categoryContainer .categoryCol2 .productsBox {
    max-width: calc(3 * 172px + 30px);
  }
}

@media only screen and (max-width: 545px) {
  .categoryContainer .categoryCol2 .productsBox {
    max-width: calc(2 * 172px + 30px);
  }
}
