@import '../../variables';

.ToolBar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 980;
  width: 100%;
  max-width: 1280px;
  padding: 0 !important;
  box-shadow: $collectionShadow;
  background-color: white;

  .toolbarIcon {
    height: 18px;
    width: auto;
    margin-bottom: 2px;
    margin-right: 5px;
  }

  .searchIcon {
    height: 15px;
    margin: auto auto 2px;
  }

  .titleSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 53px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 5px;
    max-width: 1540px;

    h1 {
      font-size: 32px;
      margin: 0;
      font-family: Arial, Helvetica, sans-serif;
    }

    .titleRowCol {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 20%;
      margin: auto;
    }

    .titleRowCol:nth-child(2) {
      width: 60%;
      align-items: center;
    }

    a {
      text-decoration: none;
      color: #000;
    }
  }

  .userSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 53px;
    margin: auto;
    padding: 10px;
    padding-top: 5px;
    background-color: #fff;
    box-sizing: border-box;
    max-width: 1540px;

    .col1 {
      width: 45%;
      display: inline-flex;
      justify-content: flex-start;
      justify-content: center;
      padding-left: 0px;

      form {
        display: inline-flex;
        width: 100%;

        .form-control:focus {
          box-shadow: none;
          border-color: $lightblue;
        }

        input {
          width: 100%;
          min-width: 250px;
          height: 38px;
          font-size: 14px;
          border-radius: 0;
        }

        .btn {
          margin-left: 0px;
          min-width: 65px;
          background-color: $gray;
        }

        .btn:focus {
          box-shadow: none;
        }
      }
    }

    .col2 {
      width: 55%;
      display: inline-flex;
      justify-content: flex-end;

      button {
        height: 38px;
        margin: 0px 8px;
        white-space: nowrap;
        color: $gray;
      }

      .btn:focus {
        box-shadow: none;
        text-decoration: none;
      }
    }
  }
}

#confirmResetPopover {
  margin: auto;
  text-align: center;

  p {
    font-size: 12px;
  }

  .btn {
    font-size: 12px;
    margin: auto;
  }
}

#cartPopover {
  max-width: none;
  border-radius: 0;

  .popover-body {
    width: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    hr {
      width: 100%;
      border: 1px solid #dee2e6;
      margin: 8px 0;
    }

    p {
      font-size: 12px;
      margin: 0 0 8px 0;
    }

    .cartBottomContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: 10px;
    }

    .toCheckoutContainer {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      a .btn,
      .btn {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        margin: auto 8px;
      }

      .btn:focus {
        box-shadow: none;
      }

      a:hover {
        text-decoration: none;
      }
    }

    .checkoutTotal {
      width: 50%;
      text-align: left;
      margin: 0px auto;
      padding: 0 5px;

      p {
        margin: 4px auto;
      }
    }

    .cartItemHeader {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 3px;
      padding: 0 3px;

      span {
        font-size: 12px;
        font-weight: bolder;
      }

      span:first-of-type {
        margin-left: 3px;
      }

      span:nth-of-type(2) {
        margin-left: 122px;
      }

      span:nth-of-type(3) {
        margin-left: 98px;
      }
    }

    .cartItemContainer {
      width: 100%;
      max-height: 276px;
      overflow: auto;

      .cartItem {
        display: inline-flex;
        width: 100%;
        height: 44px;
        padding: 2px;
        margin: 3px auto;
        border: 1px solid $lightgray;

        .cartItemImage {
          display: flex;
          flex-direction: column;
          margin: auto;
          width: 60px;
          height: 100%;
          padding: 1px 0;

          img {
            height: 100%;
            width: auto;
            margin: auto;
          }
        }

        .cartItemLink {
          display: flex;
          flex-direction: column;
          width: 100px;
          white-space: nowrap;
          margin: auto auto auto 5px;

          a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 12px;
            width: 100px;
            margin: auto;
          }
        }

        .cartItemTexts {
          display: inline-flex;
          width: 100%;

          .col {
            padding: 0 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            p {
              display: flex;
              white-space: nowrap;
              margin: 0 auto;
            }

            .plusMinusContainer {
              .plusMinusBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                padding: 0px;
                padding-bottom: 0.5px;
                max-height: 15px;
                height: 15px;
                max-width: 15px;
                width: 15px;
                margin: 3px auto;
                font-size: 12px;
                font-weight: 600;
                border: none;
              }

              .plusMinusBtn:focus {
                box-shadow: none;
              }
            }

            .cartDeleteBtn {
              width: 20px;
              height: 20px;
              padding: 0;
              font-size: 10px;
              font-weight: 600;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

#popoverLogin {
  .popover-body {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    z-index: 999;

    form {
      width: 100%;
      font-size: 14px;

      hr {
        width: 100%;
        border: 1px solid $lightgray;
        margin: 8px 0;
      }

      p {
        font-size: 14px;
        margin: 0 0 8px 0;
      }

      .btn {
        display: flex;
        margin: auto;
        font-size: 14px;
      }

      .pw-eye {
        padding: 8px;

        img {
          width: 18px;
          height: auto;
        }
      }

      .pw-eye:focus {
        outline: none;
        box-shadow: none;
      }

      .form-group {
        width: 100%;
        font-size: 14px;

        label {
          font-size: 14px;
        }

        input {
          font-size: 14px;
        }
      }
    }
  }
}

.mobileMargin {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .ToolBar {
    box-shadow: $shadow;
    max-height: 87px;

    .titleSection {
      height: 100%;
      max-height: 45px;
      padding: 10px;
      .titleRowCol:first-of-type {
        flex-direction: row;

        .LanguageToggle {
          display: none;
        }

        .barsButton {
          height: 25px;
          padding: 1px 5px;
        }

        .barsButton:focus {
          box-shadow: none !important;
        }
      }
      .titleRowCol:nth-child(2) {
        a h1 {
          font-size: 24px;
        }
      }
      .titleRowCol:last-of-type {
        display: flex;
        align-items: flex-end;
        white-space: nowrap;

        div .btn {
          height: 25px;
          padding: 1px 5px;
          color: $gray;
        }
      }
    }

    .userSection {
      height: 100%;
      max-height: 42px;
      padding: 0px 10px 10px;

      .col1 {
        padding: 0;
        form {
          .form-group {
            width: 100%;
            input {
              min-width: 200px;
              height: 32px;
            }
          }
          .btn img {
            margin-bottom: 5px;
          }
        }
      }

      .col2 {
        display: none;
      }
    }
  }

  .mobileMargin {
    display: block;
    width: 100%;
    height: 45px;
  }

  .extraMargin {
    height: 87px !important;
  }

  #cartPopover {
    max-width: calc(100vw - 10px);

    .popover-body {
      max-width: calc(100vw - 10px);
    }
  }
}

@media only screen and (min-width: $basicScreenSize) {
  .ToolBar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 980;
    width: 100%;
    max-width: 100%;

    .LanguageToggle {
      margin-left: 16px;
      margin-top: 2px;
    }
  }
  .ToolBar .userSection .col1 form input {
    margin-left: 16px;
    margin-top: 2px;
  }
  .ToolBar .userSection .col1 form .btn {
    margin-top: 2px;
    margin-left: 16px;
    height: 38px;
  }
}
