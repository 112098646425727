@import '../../variables';

.fullscreenModal .modal-dialog {
  max-height: 90vh !important;
  max-width: calc(90vh * 0.699);

  .modal-content {
    background-color: transparent;
    border: 0px solid transparent;

    .modal-body {
      .react-multi-carousel-list {
        .react-multiple-carousel__arrow:focus {
          outline: none !important;
        }

        .react-multi-carousel-track {
          align-items: center;

          .react-multi-carousel-item .modalImageContainer {
            position: relative;
            margin: auto;

            .btn {
              position: absolute;
              font-size: 18px;
              top: 25px;
              right: 25px;
              width: 40px;
              height: 40px;
              z-index: 9999;
              color: #000;
            }

            img {
              max-height: 90vh;
              max-width: -webkit-fill-available;
              max-width: -moz-available;
              display: flex;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}
