@import '../../variables';

.CookieAlert {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgb(46, 46, 46);
  color: #fff;
  font-size: 12px;
  width: 100%;
  padding: 8px 15px;
  box-shadow: $shadow;
  z-index: 999;

  p {
    margin: auto 0;
  }

  .btn {
    padding: 2px 10px;
    margin-left: 10px;
  }
}
