@import '../../variables';

.Info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  text-align: center;
  margin: auto;

  .InfoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
    margin: auto;

    p {
      text-align: left;
      margin-bottom: 15px;
    }
  }

  .versionNumber {
    margin: 30px auto;
    text-align: left;
    font-size: 14px;
    color: #212529;

    span {
      color: $blue;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Info {
    padding: 20px 15px;

    p,
    a,
    h6 {
      text-align: left;
    }
  }
}
