@import '../../variables';

.FrontPage {
  background-color: #fff;
  width: 100%;
}

.newProducts {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 50px 25px;

  h5 {
    display: flex;
  }

  .oneProduct {
    display: flex;
    flex-direction: row;
    margin: auto;

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .newProductImgContainer {
        display: flex;
        flex-direction: column;
        width: 220px;
        height: 314px;
        overflow: hidden;
        margin: auto;

        img {
          height: 314px;
          width: auto;
          object-fit: cover;
          margin: 0;
        }
      }

      .row {
        padding: 0 15px;
      }

      .newProductLogo {
        width: auto;
        height: auto;
        max-height: 45px;
        max-width: 135px;
      }
    }

    .firstCol {
      flex-direction: row;

      p {
        display: flex;
        align-items: center;
      }
    }
  }
  .newProductsPagination {
    display: flex;
    flex-direction: row;

    .chevron-uutuudet {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 0;
      margin: auto 1rem;

      span {
        margin: 0 auto;
        height: 14px;
        width: 14px;
      }
    }

    .chevron-uutuudet:hover {
      background-color: #000;
    }
  }
}

.mostViewedProducts {
  display: flex;
  flex-direction: column;
  padding: 25px;

  h5 {
    display: flex;
  }

  .topList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: auto;
    overflow: auto;
    padding: 5px 0 15px 0;
    max-width: fit-content;
  }
}

@media only screen and (max-width: 1024px) {
  .FrontPage {
    text-align: center;

    .newProducts {
      padding: 15px 10px;
      border-bottom: 1px solid $lightgray;

      h5 {
        text-align: center;
        margin: auto;
        margin-bottom: 15px;
      }

      .oneProduct {
        padding: 0;
        flex-direction: column;
        justify-content: center;

        .col:first-of-type {
          flex-direction: column;

          img {
            margin: 15px auto !important;
            max-height: 36px;
            width: 200px;
            height: auto;
          }
        }

        .col:last-of-type {
          .btn p {
            margin: auto;
          }

          p:last-of-type {
            max-width: 480px;
          }
        }

        #imageCol {
          display: flex;
          flex-direction: row;

          img {
            width: 60%;
            max-width: 240px;
            height: auto;
          }

          div {
            display: flex;
            justify-content: center;
            width: 20%;

            .chevron-uutuudet {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 44px;
              height: 44px;
              border-radius: 50%;
              background-color: rgba(0, 0, 0, 0.33);
              padding: 0;
              margin: auto 5px;

              span {
                margin: 0 auto;
                height: 14px;
                width: 14px;
              }
            }

            .chevron-uutuudet:hover {
              background-color: #000;
            }
          }
        }
      }
    }

    .mostViewedProducts {
      padding: 15px 10px;

      h5 {
        font-weight: bolder;
        text-align: center;
        margin: auto;
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (min-width: $basicScreenSize) {
  .mostViewedProducts h5 {
    display: flex;
    justify-content: center;
  }

  .mostViewedProducts {
    width: auto;
    .topList {
      display: flex;
      justify-content: space-evenly;
      max-width: 1600px;
    }
  }

  .newProducts h5 {
    display: flex;
    justify-content: center;
  }
  .newProducts {
    max-width: 1600px;
    margin: auto;
    .oneProduct {
      max-width: 1400px;
    }
  }
}
