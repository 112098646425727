.card-template {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    padding: 2mm;
    width: 210mm; /* A4 width */
    min-width: 210mm; /* A4 width */
    height: auto;
    // height: 297mm; /* A4 height */
    page-break-inside: avoid;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    /* Selainten yhdenmukaisuus parannuksia */
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
    image-rendering: auto !important;

    .upper {
        display: flex;
        height: auto;

        .section {
            flex: 0 0 auto;
            margin: 2mm;
            padding: 2mm;

            &:first-of-type {
                width: calc(45% - 4mm);
            }

            &:last-of-type {
                width: calc(55% - 4mm);
            }

            img.product-image {
                display: flex;
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 460px;
                margin: 0 auto;
            }

            img.product-logo {
                display: flex;
                width: auto;
                max-width: 67%;
                height: auto;
                max-height: 15mm;
                margin: 0 auto;
            }

            h2 {
                font-size: 24px;
                line-height: 1.2;
                padding: 2mm;
                margin: 2mm auto 0;
                text-align: center;
                word-wrap: break-word;
                font-family: Arial, Helvetica, sans-serif !important; // Tämä korjaa Firefoxin tekstien renderöinti ongelmat
            }

            .click-me {
                font-family: Arial, Helvetica, sans-serif !important; // Tämä korjaa Firefoxin tekstien renderöinti ongelmat

                :hover {
                    cursor: pointer;
                }
            }

            input,
            div#editable-title-div {
                width: 100%;
                padding: calc(2mm - 1px);
                margin: 2mm 0;
                font-size: 24px;
                font-weight: 500;
                line-height: 1.2;
                text-align: left;
                // border: 1px solid rgb(175, 175, 175);
                border: none;
                border-radius: 5px;
                font-family: Arial, Helvetica, sans-serif !important; // Tämä korjaa Firefoxin tekstien renderöinti ongelmat
                word-wrap: break-word;
                overflow-wrap: break-word;

                img {
                    display: block;
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 30mm;
                    margin: 0 auto;
                }
            }

            textarea,
            div#editable-content-div {
                width: 100%;
                padding: calc(2mm - 2px) calc(2mm - 1px);
                margin: 0 0 2mm;
                font-size: 14px;
                text-align: left;
                // border: 1px solid rgb(175, 175, 175);
                border: none;
                border-radius: 5px;
                font-family: Arial, Helvetica, sans-serif !important; // Tämä korjaa Firefoxin tekstien renderöinti ongelmat
                word-wrap: break-word;
                overflow-wrap: break-word;
                
                img {
                    display: block;
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 30mm;
                    margin: 0 auto;
                }
            }

            .btn {
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            }

            > p:not(.description > p) {
                font-size: 14px;
                padding: 2mm;
                margin: 0 auto 2mm;
                text-align: left;
                word-wrap: break-word;
            }

            .description {
                text-align: left;
                padding: 4mm 0;
                word-break: break-word;

                * {
                    font-family: Arial, Helvetica, sans-serif !important; // Tämä korjaa Firefoxin tekstien renderöinti ongelmat
                }
            }

            div.color-thumbs {
                // display: grid;
                // grid-template-columns: repeat(5, 1fr);
                // grid-gap: 0px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 106mm;

                div.color-thumb {
                    display: flex;
                    flex-direction: column;
                    width: 20%; // 5 per row
                    max-height: 115px;
                    padding: 0.25mm;
                    border-bottom: 0.5px solid rgb(175, 175, 175);

                    > * {
                        flex: 1;
                    }

                    > img {
                        display: flex;
                        height: auto;
                        max-height: calc(100% - 33px); // Yksi tekstirivi on 11px, max kolme riviä, kuvan max korkeus 82px
                        width: auto;
                        max-width: 100%;
                        margin: 0 auto;
                        padding: 0 1mm;
                    }

                    div.span-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        > span {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            font-size: 9px;
                            line-height: 11px;
                            height: fit-content;
                            max-height: 22px;
                            color: black;
                            text-align: center;
                            word-break: break-word;
                            flex: 0 1 !important;
                            font-family: Arial, Helvetica, sans-serif !important; // Tämä korjaa Firefoxin tekstien renderöinti ongelmat
                        }
                    }

                }

                span.continue-text {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 10px;
                    line-height: 12px;
                    color: #575757;
                    text-align: right;
                    padding-top: 8px;
                    width: 100%;
                    font-family: Arial, Helvetica, sans-serif !important; // Tämä korjaa Firefoxin tekstien renderöinti ongelmat
                }
            }
        }
    }

    .lower {
        display: flex;
        width: 100%;
        // max-height: 56mm;
        height: auto;
        margin-top: 2mm;

        .section {
            flex: 1;
            width: auto;
            margin: 0 2mm 2mm;
            padding: 0 2mm 2mm;
            overflow: hidden;

            img.measurement-table {
                display: flex;
                height: 56mm;
                width: auto;
                margin: 0 auto;
            }
        }
  }

  hr.page-divider {
    position: absolute;
    top: 301mm;
    margin: 0;
    border: 0;
    border-top: 1px dashed rgb(175, 175, 175);
    width: 206mm;
  }
}

@media print {
  .card-template {
    page-break-before: always; /* Force page break before A4 page */

      .lower {
    page-break-before: auto; /* Allow page break before lower content */
  }
  }
}