@import '../../variables';

.Campaigns {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  max-width: 1540px;
  margin: auto;

  p {
    width: 100%;
    max-width: 750px;
    text-align: center;
    margin: 10px auto;
  }

  h4 {
    margin: 0 auto 30px auto;
  }
  .CampaignsContainer {
    display: flex;
    width: 100%;
    max-width: 1540px;

    .CampaignButtonContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 15px auto;

      .row {
        display: flex;
        justify-content: center;
      }

      .campaignButton:hover {
        box-shadow: $shadow;
      }

      .campaignButton {
        display: flex;
        flex-direction: row;
        text-align: left;
        width: 75%;
        height: 240px;
        padding: 0px;
        margin: 10px 0px 10px 20px;
        border: 1px solid #000;

        #div1 {
          width: 238px;
          display: flex;
          flex-direction: row;
          margin: 0;

          img {
            width: 100%;
            height: 100%;
            margin: auto;
          }
        }

        #div2 {
          width: calc(100% - 238px);
          text-align: center;
          border-left: 1px solid #000;

          a {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 30px 20px;
          }

          h2 {
            text-decoration: none;
            color: black;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 15px;
          }
          p {
            margin-top: 0px;
            text-decoration: none;
            color: black;
          }
        }

        a:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }

      #div3 {
        display: flex;
        flex-direction: column;
        width: 238px;
        margin: 10px 20px;
        border: 1px solid #000;
        text-align: center;
        padding: 30px 20px;

        h6 {
          text-decoration: none;
          color: black;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        a {
          margin: 0px auto 15px auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 1240px) {
  .Campaigns {
    padding: 20px 15px;

    h4 {
      margin: auto auto 15px;
    }

    .CampaignsContainer {
      .CampaignButtonContainer {
        margin: auto;

        .row {
          margin: auto;
          flex-direction: column;
          margin-top: 40px;

          .campaignButton {
            flex-direction: column;
            width: 280px;
            height: auto;

            #div1 {
              width: 100%;
            }

            #div2 {
              width: 100%;
              border-left: none;
            }
          }

          #div3 {
            width: 280px;
            margin-bottom: 40px;
            margin-top: 0px;
          }
        }
      }
    }
  }
}
