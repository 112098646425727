.LanguageToggle {
  display: flex;
  flex-direction: row;

  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    margin: auto;
    border: 1px solid #ced4da;

    .globeIcon {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }

    p {
      font-size: 13px;
      margin: auto;
      margin-right: 8px;
      text-transform: uppercase;
    }
  }

  .btn:focus {
    box-shadow: none !important;
  }
}
