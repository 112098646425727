.appAlert {
  z-index: 9999;
  margin: auto;
  width: 100%;
  max-width: 500px;
}

@-webkit-keyframes alertFadeIn {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes alertFadeIn {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.alertFadeIn {
  -webkit-animation-name: alertFadeIn;
  animation-name: alertFadeIn;
  -webkit-animation-duration: 5.5s;
  animation-duration: 5.5s;
}

.alert-desperado {
  background-color: #000;
  color: #fff;
  border-radius: 0;

  .close:hover {
    color: #fff;
  }
}

@media only screen and (max-width: 1024px) {
  .appAlert {
    max-width: unset;
  }

  .alert-desperado {
    border-radius: 0;
  }
}
