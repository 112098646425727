.image {
  display: block;
  margin: 0 auto;
}
.image[animate='1'] {
  animation: animate 0.3s 1;
}

@keyframes animate {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
