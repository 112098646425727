@import '../../variables';

.Account {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px;
  background-color: #fff;

  h1 {
    font-size: 24px;
    margin-bottom: 2rem;
  }

  h4 {
    margin: 10px auto;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    margin: 5px;
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
  }

  .colText p:last-of-type {
    margin-bottom: 10px;
  }

  .accountContainer {
    margin-bottom: 40px;
    .row .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #cccfd2;
      margin: 0 20px auto 20px;
      min-height: 372px;
      padding: 15px;

      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        padding: 2px;

        #accountIcon {
          width: 18px;
          height: 18px;
          margin: auto;
        }
      }

      form {
        font-size: 14px;
        max-width: 60%;
        text-align: left;
        margin: 15px auto;

        .btn {
          padding: 8px;
        }

        .pw-eye {
          padding: 8px;

          img {
            width: 18px;
            height: auto;
          }
        }

        .pw-eye:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .favorites {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    width: 100%;
    flex-wrap: wrap;

    p {
      margin: 0;
    }
  }

  .orderContainer {
    .orderCol {
      border: 1px solid #cccfd2;
      margin: 0 5px auto 5px;
      padding: 16px;
      .table {
        margin: 5px auto;
        width: 100%;
        max-width: 1076px;
        font-size: 14px;
        margin-bottom: 2rem;

        tbody tr:hover {
          cursor: pointer;
        }
      }

      h4 {
        margin-bottom: 20px;
      }
    }
  }
}

.addressListModal {
  .modal-header .close {
    margin: -1rem -1rem -1rem 0;
  }

  .modal-title.h4 {
    min-width: 150px;
    text-align: center;
    margin-left: calc((100% - 150px) / 2);
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;

    p {
      margin: 20px auto;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      margin: auto;
    }

    .form-group {
      width: 100%;
      max-width: 360px;
      margin: auto;

      label {
        margin: 12px auto 5px;
        font-size: 14px;
        width: 100%;
        text-align: center;
      }

      p.validation-error {
        color: red;
        font-size: 12px;
        margin: 2px auto 15px auto;
      }
    }

    input,
    select {
      width: 100%;
      font-size: 14px;
      margin-bottom: 20px;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #bbb;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: calc(1.5em + 0.75rem + 2px);
      margin-bottom: 0px;
      font-weight: 400;
      text-align: center;
      color: #212529;
    }

    .viewAddress {
      display: flex;
      flex-direction: column;

      p {
        justify-content: center;
        text-align: center;
        margin: 5px auto;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Account {
    padding: 15px;

    h1 {
      margin: auto;
      padding: 15px;
    }

    .orderContainer {
      width: 100%;
      padding: 0;

      .orderCol {
        margin: 0 auto;
      }
    }

    .accountContainer {
      padding: 0;
      margin-bottom: 0px;
      .row {
        width: 100%;
        margin: 0 auto;
        flex-direction: column;

        .col {
          width: 100%;
          margin: auto;
          margin-bottom: 40px;

          form {
            width: 95%;
            max-width: 360px;
          }
        }

        .col:first-of-type {
          flex-basis: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .Account {
    .table {
      th,
      p {
        font-size: 12px;
      }

      th,
      td {
        padding: 2px;
      }
    }
  }
}

@media only screen and (min-width: $basicScreenSize) {
  .Account {
    justify-content: center;
    max-width: 1280px;
    margin-left: calc((100vw - 1280px) / 2);
  }
}
