@import '../../variables';

.NavContainer {
  position: relative;
  margin-top: 126px;

  .NavBar {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
    padding: 0;
    margin: auto;
    border-bottom: 2px solid #fff;
    position: relative;
    max-width: 1540px;

    .col {
      background-color: $gray;
      display: flex;
      color: #fff;
      margin: auto;
      height: 100%;
      padding: 0;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;

      a {
        display: flex;
        margin: auto;
        width: 100%;
        height: 50px;
      }

      a:hover {
        text-decoration: none;
      }

      p {
        display: flex;
        align-items: center;
        color: #ffffff;
        margin: auto;
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    .altNav {
      background-color: #000;
    }

    .ecoNav {
      p {
        color: $ecoGreen !important;
      }
    }

    .col:first-child {
      border-left: none;
    }
    .col:last-child {
      border-right: none;
    }

    .col:hover {
      cursor: default;
    }
  }

  .NavMenu {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    width: 100%;
    height: auto;
    min-height: 300px;
    position: absolute;
    z-index: 970;
    top: 48px;
    left: 0;
    -webkit-box-shadow: $navShadow;
    -moz-box-shadow: $navShadow;
    box-shadow: $navShadow;
    padding: 0;
    justify-content: center;

    .NavMenuCol {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin: 18px 0;
      border-left: 1px solid $bgGray;
      max-width: 256.66px;

      h6 {
        font-size: 13px;
        font-weight: 650;
        margin: 0;
        color: #000;
      }

      p {
        color: #000;
        margin: auto;
        font-size: 12px;
      }

      a {
        width: 100%;
        margin: 5px auto;
      }
    }

    .NavMenuCol:first-of-type {
      border-left: 1px solid #fff;
    }

    a:hover {
      text-decoration: underline;
      color: #000;
    }
  }

  .OneLine {
    width: calc(13%);
  }
  .OneLine#nav4 {
    left: calc(30% - 1px);
  }
  .OneLine#nav5 {
    left: calc(40% - 1px);
  }
  .OneLine#nav6 {
    left: calc(50% - 1px);
  }

  .active {
    background-color: #fff !important;
    p {
      color: #000 !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .NavContainer {
    display: none;
  }
}

@media only screen and (min-width: 1540px) {
  .NavContainer .OneLine#nav4 {
    left: calc(((100vw - 1540px) / 2) + (3 * 151.76px) - 1px);
  }
  .NavContainer .OneLine#nav5 {
    left: calc(((100vw - 1540px) / 2) + (4 * 151.76px));
  }
  .NavContainer .OneLine#nav6 {
    left: calc(((100vw - 1540px) / 2) + (5 * 151.76px) + 3px);
  }
}
