@import '../../variables';

.fade-transition-enter {
  opacity: 0;
}
.fade-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-transition-exit {
  opacity: 1;
}
.fade-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.Product {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.productComponent {
  display: flex;

  justify-content: center;
  margin: 0 auto;
  width: 90%;
  padding: 40px 0;

  .col {
    display: flex;

    flex-direction: column;

    justify-content: flex-start;

    .row {
      display: flex;
      justify-content: flex-start;

      h5 {
        font-size: 20px;
        font-weight: 650;
        text-transform: uppercase;
      }

      h6 {
        font-size: 14px;
        color: #505050;
      }

      .productLogo {
        margin-right: 50px;

        img {
          max-height: 50px;
          max-width: 150px;
        }
      }
    }
  }

  .priceTable {
    margin-top: 15px;
    width: 100%;
    background-color: #e6e6e6;

    .infoIcon {
      height: 16px;
      width: auto;
      margin-bottom: 0px;
      margin-left: 5px;
      background-color: white;
      border-radius: 50%;
    }

    .row {
      height: 28px;

      p {
        margin: auto;
        margin-top: 4px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150%;
        padding-left: 5px;

        .strikethrough {
          text-decoration: line-through;
        }

        .salePrice {
          color: red;
          margin-left: 5px;
        }
      }

      .col {
        .plusMinusBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          padding: 0px;
          max-height: 22px;
          height: 22px;
          max-width: 22px;
          width: 22px;
          width: -moz-available;
          margin: 3px auto;
          font-size: 18px;
          padding-bottom: 3px;
          font-weight: 600;
          border: none;
        }

        input:focus::placeholder {
          color: transparent;
        }

        .plusMinusBtn:focus {
          box-shadow: none;
        }
      }
    }

    .row:nth-child(even) {
      background-color: #fff;
    }

    .counter {
      display: inline-flex;
      justify-content: center;
      height: 28px;
      max-width: 36px;

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }

      input {
        width: 100%;
        min-width: 36px;
        height: 20px;
        font-size: 12px;
        text-align: center;
        border: 1px solid rgb(99, 99, 99);
        border-radius: 5px;
        margin: 4px 0;
      }

      .btn {
        width: 20%;
        height: 22px;
        margin: 3px;
        padding: 0;

        p {
          font-size: 14px;
          font-weight: bolder;
          margin: 0;
        }
      }
    }
  }
}

#row1 {
  height: 50px;
  padding: 0;
  margin-bottom: 10px;
}

#rowProduct {
  margin-top: 5px;
  margin-bottom: 5px;

  p {
    margin: 5px 0;
  }

  #price {
    font-size: 18px;
    margin-bottom: 5px;
  }
}

#rowKuvaus {
  display: block;
  margin-bottom: 5px;
  text-align: left;

  p {
    text-align: left;
    margin: 5px 0;
  }

  li {
    font-size: 14px;
  }
}

#row3 {
  margin-top: 20px;
  margin-bottom: 10px;

  p {
    margin-right: 20px;
    margin-bottom: 0;
    height: 35px;
    display: flex;

    flex-direction: column;

    justify-content: center;
  }

  .form-group {
    margin: 0;

    #selectColor {
      font-size: 14px;
    }

    .form-control {
      font-size: 14px;
    }
  }
}

.colorButtons {
  padding-bottom: 10px !important;

  .colorButton {
    display: flex;
    flex-direction: column;
    width: 48px;
    height: 48px;
    padding: 4px;
    margin: 4px !important;
    border: 1px solid #fff;
    border-radius: 50px;

    img {
      margin: 0;
      height: 100%;
      width: auto;
      border-radius: 50px;
      object-fit: cover;
    }
  }

  .colorButton:hover {
    cursor: pointer;
    border: 1px solid $lightblue;
    -webkit-box-shadow: 0px 0px 1px 1px $lightblue;
    -moz-box-shadow: 0px 0px 1px 1px $lightblue;
    box-shadow: 0px 0px 1px 1px $lightblue;
  }
}

.activeColor {
  -webkit-box-shadow: 0px 0px 3px 1px #000;
  -moz-box-shadow: 0px 0px 3px 1px #000;
  box-shadow: 0px 0px 3px 1px #000;
}

.activeColor:hover {
  cursor: default !important;
  border: 1px solid #fff !important;
  -webkit-box-shadow: 0px 0px 3px 1px #000 !important;
  -moz-box-shadow: 0px 0px 3px 1px #000 !important;
  box-shadow: 0px 0px 3px 1px #000 !important;
}

#row5 {
  justify-content: flex-end;
  margin-top: 10px;

  .addToCartButton {
    background-color: #212529;
    border-color: #212529;
    color: #fff;
    font-size: 14px;
  }
}

.nav-tabs .nav-link {
  width: 25%;
}

.tab-content {
  min-height: 100px;
  padding: 30px;
  white-space: pre-wrap;

  #mittataulukko {
    margin: 0.5rem auto;
    height: fit-content;
    height: 260px;
    width: auto;
  }

  .attachmentsList {
    list-style-type: none;
  }

  .contactForm {
    width: 100%;
    max-width: 450px;
    margin: auto;
    text-align: left;

    form {
      input,
      textarea {
        font-size: 14px;
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 4px;
        padding: 6px 12px;
      }

      label {
        font-size: 14px;
        margin-top: 16px;
        width: 100%;
      }

      .btn {
        display: flex;
        margin: 16px auto;
        text-align: center;
        padding: 10px 15px;
      }

      .validation-error {
        font-size: 14px;
        margin-top: 2px;
        color: $red;
        text-align: left;
      }

      p {
        font-size: 14px;
        margin: 16px auto;
        text-align: center;
      }
    }
  }
}

.similarButtons {
  display: flex;
  flex-direction: column;
  padding: 25px 0;

  h5 {
    display: flex;

    margin: 0 auto 10px auto;
  }

  .similarList {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin: auto;
    overflow: auto;
    max-width: fit-content;
  }
}

.imagePreview {
  .imagePreviewSelected {
    display: flex;
    flex-direction: column;

    width: 364px;
    height: 520px;
    overflow: hidden;
    margin: 0 auto 15px auto;
    padding: 0;
    border: 1px solid #fff;

    img {
      margin: 0;
      height: 100%;
      height: -moz-fit-content;
      width: auto;
      object-fit: cover;
    }

    figcaption {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s, opacity 0.3s linear;
      display: flex;

      flex-direction: column;

      justify-content: center;
      width: 364px;
      position: relative;
      color: #fff;
      font-size: 16px;
      padding: 5px;
      height: 70px;
      bottom: calc((518px + 70px) / 2);
      z-index: 960;
      background-color: rgba(0, 0, 0, 0.5);

      .zoomIcon {
        width: 26px;
        height: 26px;
        margin: auto;
      }
    }

    .outletTag {
      display: block;
      position: absolute;
      bottom: 160px;
      left: 107px;
      background-color: red;
      height: 30px;
      width: 110px;
      z-index: 99;

      p {
        margin: 3px auto;
        font-size: 16px;
        padding: 0 15px;
        color: #fff !important;
        font-weight: 500 !important;
      }
    }
  }

  .imagePreviewSelected:hover {
    cursor: pointer;
    figcaption {
      visibility: visible;
      opacity: 1;
    }
  }

  .imagePreviewButtons {
    display: block;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    padding: 5px;
    min-width: 364px;
    max-width: 520px;
    width: fit-content;
    height: auto;
    overflow: hidden;

    img {
      margin: 10px;
      height: 74px;
      width: auto;
      border: 1px solid $lightgray;
    }

    img:hover {
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 1px 1px $lightblue;
      -moz-box-shadow: 0px 0px 1px 1px $lightblue;
      box-shadow: 0px 0px 1px 1px $lightblue;
    }
  }
}

.imageToolsContainer {
  display: flex;
  justify-content: center;
  margin: 10px auto;

  .btn {
    margin: 0 5px;
  }

  .product-card-link {
    text-decoration: none;
  }
}

.tab-content {
  white-space: normal;
}

.kuvausTab {
  text-align: left;
  font-size: 14px;
  width: 100%;
  max-width: 640px;
  margin: auto;
}

.addToFavoriteButton {
  display: flex !important;

  justify-content: center;
  margin: 10px auto 0 auto !important;
  max-height: 36px;

  p {
    margin-right: 5px;
  }

  img {
    margin: 0 !important;
    width: 18px;
    height: 18px;
    padding-top: 2px;
  }
}

.isFavoriteText {
  display: flex;
  justify-content: center;
  margin: 15px auto;

  p {
    color: #7d7d7d;
    font-weight: 600;
    margin: 0 5px auto 0;
  }

  img {
    width: 18px;
    height: 18px;
    padding-top: 2px;
    margin-left: 0px !important;
  }
}

.removeFavoriteButton {
  font-size: 13px !important;
  padding: 5px;
  margin: 0 auto;
}

.tooltipInfoText {

  .tooltip-inner {
    max-width: 90vw;
    width: 320px;
    padding: 8px;

    span {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 1024px) {

  .tooltipInfoText.tooltip.show {
    display: block;
  }

  .Product {
    .productComponent {
      display: flex;
      flex-direction: column;
      padding: 0 15px 30px;
      width: 100%;
      height: auto;

      .col {
        padding: 0;

        .addToFavoriteButton {
          margin-bottom: 15px !important;
        }

        #row1,
        #row3,
        #rowProduct {
          justify-content: center;
          margin-top: 0;
          margin-bottom: 5px;
        }

        #row3 {
          border-top: 1px solid $lightgray;
          padding-top: 16px;
          margin: 16px 0;

          .form-group {
            width: 100%;
            max-width: 280px;
            text-align: center;
          }
          p {
            display: none;
          }
        }

        .productLogo {
          margin: auto;
        }

        .imagePreview {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .imagePreview .imagePreviewButtons img {
          margin: 10px 5px;
        }

        .imagePreview .imagePreviewSelected {
          margin-right: 0;
          margin-left: 0;
          margin: auto;
          height: 40vh;
          max-width: 100%;

          img {
            height: 100%;
            width: auto;
            margin: auto;
          }

          .outletTag {
            bottom: 105px;
            left: calc((100vw - 110px - 30px) / 2);
          }
        }
      }

      .col:last-of-type {
        margin: 0 auto;
        .row {
          width: 100%;
          margin: auto;
        }

        #row5 {
          margin-top: 15px;
          justify-content: center;
        }

        .colorButtons {
          margin: 0 auto;

          .colorButton {
            width: calc(12.5vw - 12px);
            height: calc(12.5vw - 12px);
            min-width: 46px;
            min-height: 46px;
            max-width: 60px;
            max-height: 60px;
          }
        }
      }

      #mobile-rows-col {
        display: flex !important;
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px;
        border-top: 1px solid $lightgray;
        border-bottom: 1px solid $lightgray;
        margin-bottom: 16px;

        #mobile-row1 {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          height: fit-content;
          min-height: 100px;

          h6 {
            font-size: 15px;
          }

          img {
            height: 40px;
            width: max-content;
            margin: auto;
          }
        }

        #mobile-row2 {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          align-content: center;
          margin-top: 16px;
          padding-top: 8px;

          p {
            font-size: 20px;
            margin: 0;
          }

          .btn {
            p {
              font-size: 14px;
            }
          }
        }
      }
    }

    .tab-content {
      padding: 20px;
      border-bottom: 1px solid $lightgray;
      #mittataulukko {
        width: 100%;
        height: auto !important;
      }
    }

    .nav-tabs a {
      width: 50%;
      border: 1px solid #dee2e6;
    }

    .nav-tabs {
      border: none;

      .active {
        font-weight: 650;
      }
    }

    .similarButtons {
      padding: 20px 15px;
    }

    .imagePreviewSelected:hover {
      figcaption {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  .tooltip.show {
    display: none;
  }
}

@media only screen and (min-width: $basicScreenSize) {
  .Product {
    .productComponent {
      max-width: 1200px;
    }
  }
  .nav-tabs {
    max-width: 1600px;
    width: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .Product .productComponent .col:last-of-type .colorButtons {
    max-width: calc(10 * 68px);
  }
}

@media only screen and (max-width: 708px) {
  .Product .productComponent .col:last-of-type .colorButtons {
    max-width: calc(9 * 68px);
  }
}

@media only screen and (max-width: 640px) {
  .Product .productComponent .col:last-of-type .colorButtons {
    max-width: calc(8 * 68px);
  }
}

@media only screen and (max-width: 460px) {
  .Product .productComponent .col:last-of-type .colorButtons {
    max-width: calc(7 * 54px);
  }
}

@media only screen and (max-width: 407px) {
  .Product .productComponent .col:last-of-type .colorButtons {
    max-width: calc(6 * 54px);
  }
}

@media only screen and (max-width: 378px) {
  .Product .productComponent .col:last-of-type .colorButtons {
    max-width: calc(5 * 54px);
  }
}
